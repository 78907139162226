<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-15 16:59:34
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-01-25 15:22:52
 * @Description:  plotRepairBrazil
-->
<template>
  <div id="plotRepairBrazil">
    <div class="draw-title">
      <el-button class="butStyle" size="mini" @click="newPlot"
        >新建基地</el-button
      >
      <!-- <el-button class="butStyle" size="mini" @click="eaitNewPlot">编辑</el-button> -->
      <el-button class="butStyle" size="mini" @click="removeNewPlot"
        >删除</el-button
      >
      <el-button
        v-if="saveState == 0"
        class="butStyle"
        size="mini"
        @click="saveNewPlot"
        >保存</el-button
      >
    </div>
    <drawMap ref="drawMap" />

    <div class="drawList">
      <div class="nameTitle">基地信息</div>
      <div class="scroll">
        <div class="noneList" v-if="tableData.length == 0">暂无基地</div>
        <div v-else>
          <div class="nameBox" v-for="(item, index) in tableData" :key="index">
            <div class="bases">
              基地名称：<span>{{ item.name }}</span>
            </div>
            <div class="batchs">
              基地面积：<span>{{ item.enterpriseName }}</span>
            </div>
            <div class="area">
              基地信息：<span>{{ item.areaInfo }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import drawMap from "@/components/soureUnit/massifDraw.vue";
export default {
  components: {
    drawMap,
  },
  data() {
    return {
      saveState: "1",
      tableData: [],
      pageNow: 1,
      pageSize: 1000,
      dataTotal: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      this.loading = true;
      const { data } = await this.$get("/enterpriseManage/querryLandPlot", {
        page: this.pageNow,
        size: this.pageSize,
        id: "",
        name: "",
      });
      this.tableData = data.datas;
      this.dataTotal = data.count;
    },
    // 新建基地
    newPlot() {
      this.$refs.drawMap.draw();
    },
    // 编辑
    // eaitNewPlot() {
    //   if (this.saveState != 1) return;
    //   this.$refs.drawMap.defaultsr();
    //   let title = this.$refs.drawMap.titleEait;
    //   this.saveState = title;
    // },

    // 删除
    removeNewPlot() {
      this.$refs.drawMap.deceltMassif();
    },
    // 保存
    saveNewPlot() {
      this.saveState = 1;
      this.$refs.drawMap.endState = true;
      this.$refs.drawMap.eaitMassifInfo();
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/style/colorPublic";
#plotRepairBrazil {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.draw-title {
  position: absolute;
  top: 2vh;
  left: 5vh;
  width: 81vw;
  height: 6vh;
  background: #fff;
  z-index: 999999;
  border-radius: 10px;
  line-height: 6vh;
  padding-left: 3vh;
  box-sizing: border-box;
}
.butStyle {
  background: @but_color;
  color: #fff;
}
@media screen and (max-width: 1500px) {
  .draw-title {
    height: 7vh;
    line-height: 7vh;
    left: 7vh;
  }
}
.drawList {
  width: 40vh;
  height: 76vh;
  position: absolute;
  top: 10vh;
  right: 35vh;
  color: rgb(51, 50, 50);
  // background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
.scroll {
  height: 70vh;
  overflow-y: scroll;
}
.nameTitle {
  text-align: center;
  font-size: 3vh;
  font-family: "楷体";
  font-style: italic;
}
.noneList {
  font-size: 3vh;
  margin-top: 3vh;
  text-align: center;
}
.nameBox {
  width: 90%;
  height: 14vh;
  margin: 2vh auto;
  border: 1px solid rgb(151, 151, 151);
  // background-color: pink;
  font-size: 1.6vh;
  overflow: hidden;
}
.bases {
  margin-left: 2vh;
  margin-top: 1.5vh;
}
.batchs {
  margin-left: 2vh;
  margin-top: 1.5vh;
}
.area {
  margin-top: 1.5vh;
  margin-left: 2vh;
}
.nameBox span {
  margin-left: 1vh;
  color: #0049d0;
}
</style>
