<template>
  <div id="conter">
    <div id="map-container"></div>
    <el-dialog
      :close-on-click-modal="false"
      class="isDialog"
      :before-close="cancel"
      :title="newTitle"
      :visible.sync="endState"
      :destroy-on-close="true"
      width="35%"
    >
      <spring
        ref="spring"
        :stateText="stateText"
        :recordData="recordData"
        class="transition-box"
        @determine="determine"
        @cancel="cancel"
      />
    </el-dialog>
  </div>
</template>
<script>
import "../../../node_modules/ol/ol.css";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import OSM from "ol/source/OSM.js";
import Draw from "ol/interaction/Draw";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { transform } from "ol/proj";
import { WKT } from "ol/format";
import colormap from "colormap";
import { getArea, getLength } from "ol/sphere";
import XYZ from "ol/source/XYZ";
import {
  Modify,
  Select,
  defaults as defaultInteractions,
} from "ol/interaction";
import spring from "@/components/bulletFrame.vue";
import { unByKey } from "ol/Observable";
import { get } from "ol/proj";

export default {
  components: {
    spring,
  },
  data() {
    return {
      map: null,
      source: new VectorSource({
        wrapX: false,
      }),
      source2: new VectorSource({
        wrapX: false,
      }),
      endState: false,
      modify: null,
      vector: null,
      batchLayer: null,
      graphicModification: null,
      selectedFeature: null,
      selectByAttribute: null,
      plotFeatures: null,
      TileLayerFirst: null,
      titleEait: 1,
      stceltFace: null,
      listener: null,
      r: null,
      polygonWkt: null,
      recordData: null,
      addAndEait: true,
      stateText: "新建基地",
      nonBatch: true,
      massifList: [],
      batchRectangle: null,
      interactionModify: null,
      projection: get("EPSG:4326"),
      newTitle: "新建基地",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    clamp(value, low, high) {
      return Math.max(low, Math.min(value, high));
    },
    getColor(feature) {
      const min = 1e4; // 最小
      //const max = 2e13; // 大
      const max = 1e13; // 大
      const steps = 50;
      const ramp = colormap({
        colormap: "blackbody",
        nshades: steps,
      });
      const area = getArea(feature.getGeometry());
      const f = Math.pow(this.clamp((area - min) / (max - min), 0, 1), 1 / 2);
      const index = Math.round(f * (steps - 1));
      return this.rgba(this.hexColorToRgba(ramp[index]));
    },
    rgba(rgbaArray) {
      return "rgba(" + rgbaArray.join(",") + "," + "0.6" + ")";
    },
    hexColorToRgba(hexColor, alphaMaxVal = 1) {
      hexColor = hexColor.replace("#", "");
      //用于分割16进制色彩通道
      let reg = new RegExp("\\w{1,2}", "g");
      //分割颜色通道
      let rgbaArray255 = hexColor.match(reg);
      rgbaArray255 = rgbaArray255.map((channel, index) => {
        //计算每个通道的10进制值
        let colorVal = parseInt(channel, 16);
        if (index === 3) {
          //这是alpha通道
          return Math.round((colorVal / (255 / alphaMaxVal)) * 100) / 100;
        }
        return colorVal;
      });
      return rgbaArray255;
    },
    searchDrawLayers(layer) {
      var dics = layer.getProperties();
      if (dics["title"] == "企业") {
        return true;
      }
    },
    /**
     * @description: init
     */
    init() {
      let _this = this;
      this.vector = new VectorLayer({
        source: this.source,
        style: function(feature) {
          if (feature.explain == "massif") {
            return new Style({
              fill: new Fill({
                color: "rgba(255,255,255,0.3)",
              }),
              stroke: new Stroke({
                color: "#01effd",
                width: 4,
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: _this.getColor(feature),
                }),
              }),
            });
          } else {
            return new Style({
              //   fill: new Fill({ color: "rgba(0,255,0,0.3)" }),
              //   stroke: new Stroke({ color: "#2aff00", width: 4 }),
              //   image: new CircleStyle({
              //     fill: new Fill({ color: _this.getColor(feature) })
              //   })
              fill: new Fill({
                color: "rgba(255,255,255,0.3)",
              }),
              stroke: new Stroke({
                color: "#01effd",
                width: 4,
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: _this.getColor(feature),
                }),
              }),
            });
          }
        },
      });
      this.batchLayer = new VectorLayer({
        style: function(feature) {
          return new Style({
            fill: new Fill({
              color: "rgba(0,255,0,0.3)",
            }),
            stroke: new Stroke({
              color: "#2aff00",
              width: 4,
            }),
            image: new CircleStyle({
              fill: new Fill({
                color: _this.getColor(feature),
              }),
            }),
          });
        },
        source: this.source2,
      });
      const raster = new TileLayer({
        source: new OSM(),
      });
      var layer = new TileLayer({
        source: new OSM(),
        wrapX: false,
      });
      this.graphicModification = new Select({
        wrapX: false,
        layers: [this.vector],
      });
      this.modify = new Modify({
        features: this.graphicModification.getFeatures(),
      });
      const key = "e710f7d4feed2bb53993b9e7797d34de";
      this.batchRectangle = new Select({
        wrapX: false,
        layers: [this.batchLayer],
      });
      this.interactionModify = new Modify({
        features: this.batchRectangle.getFeatures(),
      });
      const attributions =
        '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
      this.TileLayerFirst = [
        new TileLayer({
          source: new XYZ({
            attributions: attributions,
            //   url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=' + key,
            url:
              "http://t0.tianditu.com/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=" +
              key,
            tileSize: 512,
          }),
        }),
        // 第二个地图语言包
        new TileLayer({
          source: new XYZ({
            crossOrigin: "anonymous",
            url:
              "http://t0.tianditu.com/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=" +
              key,
            wrapX: false,
          }),
        }),
        this.vector,
      ];

      this.map = new Map({
        target: "map-container",
        interactions: defaultInteractions().extend([
          this.graphicModification,
          this.modify,
        ]),
        // layers: [layer],
        // layers: [raster, vector],
        view: new View({
          //   center: transform([113.625351, 34.746303], 'EPSG:4326', 'EPSG:3857'),
          center: transform([119.69,31.34], "EPSG:4326", "EPSG:3857"),
          zoom: 15,
        }),
        layers: this.TileLayerFirst,
      });
      let wawt = {
        wkt: [
          "POLYGON((12634842.89389322 4131120.4636085667,12647569.659102703 4134713.00393797,12634842.89389322 4118814.102054653,12634842.89389322 4131120.4636085667))",
        ],
        centers: "12648716.21452698,4129457.958243364",
      };
      const myDraw = JSON.parse(window.localStorage.getItem("draw"));
      let arrs = [myDraw, wawt];
      //   arrs.forEach((v) => {
      //     var newfeature = new WKT().readFeature(v.wkt[0]);
      //     this.source.addFeature(newfeature);
      //     // this.source.removeFeature(newfeature);
      //     //console.log(newfeature);
      //   });
      this.addMassif();
      this.modify.setActive(false);
      this.modify.on("modifyend", this.modifyIconEnd);
      this.map.on("click", this.clickDarw);
      this.graphicModification.on("select", this.darwEvent);
      this.map.addLayer(this.batchLayer);
      this.batchRectangle.on("select", this.batchChange);
      this.interactionModify.setActive(true);
      // //鼠标获取坐标
      // const mousePositionControl = new MousePosition({
      //   coordinateFormat: createStringXY(2),
      //   projection: 'EPSG:4326',
      //   className: 'custom-mouse-position',
      //   target: document.getElementById('mouse-position'),
      //   undefinedHTML: '&nbsp;'
      // });
      //   this.map.addControl(mousePositionControl);
      //   const mousePositionControl = new MousePosition({
      //     coordinateFormat: function(coordinate) {
      //       return format(coordinate, '经度:{x} 纬度:{y}', 2);
      //     },
      //     projection: 'EPSG:4326',
      //     className: 'custom-mouse-position',
      //     target: document.getElementById('mouse-position'),
      //     undefinedHTML: '&nbsp;',
      //   });
      //添加控件到地图
      // this.map.addControl(mousePositionControl);
    },
    // 获取基地
    addMassif() {
      this.$get("/enterpriseManage/querryLandPlot", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log(res);
          let datas = res.data.datas;
          this.massifList = res.data.datas;
          datas.forEach((v) => {
            if (v.polygonWkt) {
              if (this.isJSON_test(v.polygonWkt)) {
                v.polygonWkt = JSON.parse(v.polygonWkt);
                var newfeature = new WKT().readFeature(v.polygonWkt[0]);
                newfeature.massifId = v.id;
                newfeature.name = v.name;
                newfeature.explain = "massif";
                this.source.addFeature(newfeature);
              }
            }
          });
        }
      });
    },
    /**
     * @description:  批次
     * @param {*}
     */
    ruquestBatch() {
      this.$get("/plantingBatch/getPlantingBatchList", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data.datas);
          let { datas } = res.data;
          datas.forEach((p) => {
            let newfeature = new WKT().readFeature(p.location);
            newfeature.massifId = p.id;
            newfeature.batchName = p.name;
            newfeature.explain = "batch";
            this.source.addFeature(newfeature);
          });
        }
      });
    },
    // 删除
    deceltMassif() {
      let state = this.selectedFeature;
      if (!state || state.length == 0) {
        return this.$message.info("请先选择一个基地进行删除!");
      }
      this.$confirm("此操作将永久删除该地块, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/enterpriseManage/delLandPlot", {
            landPlotId: state[0].massifId,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success(res.data.msg);
              this.source.removeFeature(state[0]);
              this.$router.go(0);
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * @description: 是否可json
     * @param {*} str 字符串
     */

    isJSON_test(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          //console.log(obj);
          return true;
        } catch (e) {
          //console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
    saveFeature(features) {
      var wktList = [];
      for (var i = 0; i < features.length; i++) {
        let feature = features[i];
        let strwkt = new WKT().writeFeature(feature, {
          dataProjection: "EPSG:3857", //目标坐标系
          featureProjection: "EPSG:3857", //当前坐标系'EPSG:3857', 'EPSG:4326'
        });
        let wktJsonObject = strwkt;
        wktList.push(wktJsonObject);
      }
      return wktList;
    },
    defaultsr() {
      let state = this.selectedFeature;
      if (!state || state.length == 0)
        return this.$message.info("请先选择一个基地进行修改!");
      this.titleEait = "0";
      this.graphicModification.setActive(false);
      this.modify.setActive(true);
      this.echoData(state[0].massifId);
      this.addAndEait = false;
    },
    eaitMassifInfo() {
      this.$nextTick(() => {
        this.$refs.spring.echoList();
      });
    },
    /**
     * @description:回显
     * @param {*}
     * @return {*}
     */
    echoData(landPlotId) {
      this.$get("/enterpriseManage/fatchLandPlot", {
        landPlotId,
      }).then((res) => {
        this.recordData = res.data.data;
        this.endState = true;
        this.$nextTick(() => {
          this.$refs.spring.echoList(res.data.data);
        });
      });
    },
    /**
     * @description:
     * @param {*} e 修改参数
     * @return {*}
     */
    modifyIconEnd(e) {
      const center = this.map.getView().getCenter();
      const cneters = center[0] + "," + center[1];
      let lastFeatures = [];
      let features = this.source.getFeatures();
      window.localStorage.setItem(
        "draw",
        JSON.stringify({
          wkt: this.saveFeature(features),
          centers: cneters,
        })
      );
      lastFeatures.push(
        features.length == 0 ? features[0] : features[features.length - 1]
      );
      this.polygonWkt = {
        wkt: this.saveFeature(lastFeatures),
        id: lastFeatures[0].massifId,
        centers: cneters,
        md: "修改",
      };
      //   //console.log(this.polygonWkt);
    },
    /**
     * @description:
     * @param {*} 绘制
     */
    draw() {
      this.addAndEait = true;
      this.graphicModification.setActive(false);
      this.graphicModification.getFeatures().clear();
      var draw = new Draw({
        source: this.source,
        type: "Polygon",
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 0, 0.7)",
          }),
          stroke: new Stroke({
            color: "rgba(255, 255, 0, 0.9)",
            lineDash: [10, 10],
            width: 3,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(255, 255, 0, 0.7)",
            }),
            fill: new Fill({
              color: "rgba(255, 255, 0, 0.7)",
            }),
          }),
        }),
      });
      this.map.addInteraction(draw);
      //   draw.removeLastPoint(); //先保留到图层 然后弹框取消事件  删除。
      let _this = this;
      _this.vector.on("change", function(evt) {
        let lastFeatures = [];
        // 获取矢量图层数据源
        let vectorSource = evt.target.getSource();
        if (vectorSource.getState() === "ready") {
          const center = _this.map.getView().getCenter();
          var point = transform(
            [center[0], center[1]],
            "EPSG:3857",
            "EPSG:4326"
          );
          let features = vectorSource.getFeatures();
          for (var s = 0; s < features.length; s++) {
            if (!features[s].name) {
              lastFeatures.push(features[s]);
            }
          }
          _this.polygonWkt = {
            wkt: _this.saveFeature(lastFeatures),
            // centers: cneters,
            centers: point[0] + "," + point[1],
            md: "添加",
          };
          _this.map.removeInteraction(draw);
          _this.selectedFeature = [];
        }
      });
      draw.on("drawend", function(evt) {
        //console.log(evt.feature, "dsadasdad");
        //console.log(evt);
        _this.map.removeInteraction(draw);
        _this.selectedFeature = [];
        _this.endState = true;
        _this.newTitle = "新建基地";

        let size = getArea(evt.feature.geometryChangeKey_.target, {
          projection: this.projection,
          radius: "6371004",
        });
        // if (size > 10000) {
        //   size = Math.round((size / 1000000) * 100) / 100 + " " + "km²";
        // } else {
        size = Math.round(size * 100) / 100 + " " + "m²";
        // }
        _this.$nextTick(() => {
          _this.$refs.spring.addMassif.enterpriseName = size;
        });
      });
    },
    /**
     * @description:
     * @param {*} 绘制批次
     */
    batch() {
      let state = this.selectedFeature;
      let massifType = this.selectedFeature[0].explain;
      if (!state || state.length == 0) return alert("请选择一个基地添加批次!");
      if (massifType != "massif") {
        this.graphicModification.getFeatures().clear();
        return this.$message.info("此区域已是批次，不可进行添加操作！");
      }
      this.graphicModification.setActive(false);
      var draw = new Draw({
        source: this.source,
        type: "Polygon",
        freehandCondition: this.freehandCondition,
        condition: this.condition,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 0, 0.7)",
          }),
          stroke: new Stroke({
            color: "rgba(255, 255, 0, 0.9)",
            lineDash: [10, 10],
            width: 3,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(255, 255, 0, 0.7)",
            }),
            fill: new Fill({
              color: "rgba(255, 255, 0, 0.7)",
            }),
          }),
        }),
      });
      this.map.addInteraction(draw);
      let _this = this;
      draw.on("drawend", function(evt) {
        _this.map.removeInteraction(draw);
        _this.$emit("openPopup");
        // _this.nonBatch = false;
        // _this.modelState = true;
        // _this.$nextTick(() => {
        //   _this.$refs.modelCom.nonBatch = false;
        // });

        // _this.endState = true;
        var polygon = evt.feature.getGeometry();
        let dsak = transform(polygon.flatCoordinates, "EPSG:3857", "EPSG:4326");
        _this.r = null;
        unByKey(_this.listener);
        // //console.log(unByKey(_this.listener));
      });
      this.vector.on("change", function(evt) {
        let lastFeatures = [];
        let vectorSource = evt.target.getSource();
        if (vectorSource.getState() === "ready") {
          const center = _this.map.getView().getCenter();
          const cneters = center[0] + "," + center[1];
          let features = vectorSource.getFeatures();
          lastFeatures.push(
            features.length == 0 ? features[0] : features[features.length - 1]
          );
          let batchWkt = {
            wkt: _this.saveFeature(lastFeatures),
            centers: cneters,
            md: "添加",
          };
          _this.map.removeInteraction(draw);
          _this.$emit("openState", {
            batchWkt,
            id: _this.selectedFeature[0].massifId,
            name: _this.selectedFeature[0].name,
          });
        }
      });
      //   draw.on('drawstart', function(evt) {
      //     let tooltipCoord = evt.coordinate;
      //     _this.r = evt.feature;
      //     _this.listener = _this.r.getGeometry().on('change', function(evt) {
      //       const geom = evt.target;
      //       let output;
      //       output = _this.formatArea(geom);
      //       tooltipCoord = geom.getInteriorPoint().getCoordinates();
      //     });
      //   });
    },
    /**
     * @description: 批次修改
     * @param {*}
     */

    batchModification() {
      //   this.graphicModification.getFeatures().clear(); //图层
      //   this.graphicModification.setActive(false);
      //   this.map.addInteraction(this.batchRectangle);
      //   this.selectedFeature = null;
      //   if (!this.selectByAttribute) this.$message.info('请选择批次后点击编辑进行编辑!');
      //   this.map.addInteraction(this.interactionModify);
      let massifType = this.selectedFeature[0].explain;
      if (massifType != "batch")
        return this.$message.info("基地不可进行修改！");
      this.modify.setActive(true);
      this.graphicModification.setActive(false);
      this.$emit("eaitState", this.selectedFeature[0].massifId);
    },
    /**
     * @description:
     * @param {*} 批次保存
     */

    changeGetGeometry(evt) {
      const geom = evt.target;
      let output = this.formatArea(geom);
      //console.log(output);
    },
    //
    freehandCondition(e) {},
    /**
     * @description:
     * @param {*}
     */
    condition(e) {
      var geo = this.selectedFeature[0].getGeometry(); //feture是几何元素
      return geo.intersectsCoordinate(e.coordinate);
    },
    IsPtInPoly(ALon, ALat, APoints) {
      //console.log(this.selectedFeature[0]);
      var iSum = 0,
        iCount;
      var dLon1, dLon2, dLat1, dLat2, dLon;
      if (APoints.length < 3) return false;
      iCount = APoints.length;
      for (var i = 0; i < iCount; i++) {
        if (i == iCount - 1) {
          dLon1 = APoints[i].lng;
          dLat1 = APoints[i].lat;
          dLon2 = APoints[0].lng;
          dLat2 = APoints[0].lat;
        } else {
          dLon1 = APoints[i].lng;
          dLat1 = APoints[i].lat;
          dLon2 = APoints[i + 1].lng;
          dLat2 = APoints[i + 1].lat;
        }
        //以下语句判断A点是否在边的两端点的水平平行线之间，在则可能有交点，开始判断交点是否在左射线上
        if (
          (ALat >= dLat1 && ALat < dLat2) ||
          (ALat >= dLat2 && ALat < dLat1)
        ) {
          if (Math.abs(dLat1 - dLat2) > 0) {
            //得到 A点向左射线与边的交点的x坐标：
            dLon = dLon1 - ((dLon1 - dLon2) * (dLat1 - ALat)) / (dLat1 - dLat2);
            if (dLon < ALon) iSum++;
          }
        }
      }
      if (iSum % 2 != 0) return true;
      return false;
    },
    clickDarw(e) {
      // //console.log(e, '普通地图点击事件')
    },
    cancel() {
      this.endState = false;
      this.graphicModification.setActive(true);
      if (this.addAndEait) {
        let features = this.source.getFeatures();
        for (var z = 0; z < features.length; z++) {
          if (!features[z].name) {
            this.source.removeFeature(this.source.getFeatures()[z]);
          }
        }
      }
      // this.source.removeFeature(
      //     this.source.getFeatures()[this.source.getFeatures().length - 1]
      // );

      //   setTimeout(()=>{
      //       this.source.removeFeature(this.source.getFeatures()[0]);
      //   },500)
      //   this.source.removeFeature(this.source.getFeatures()[0]);
      //   this.source.removeFeature(this.vectorSource[0]); //
    },
    /**
     * @description:
     * @param {*} addAndEait true 是修改   false 是添加       添加要删除最后一个基地
     */
    batchPopup(addAndEait) {
      if (!addAndEait)
        this.source.removeFeature(
          this.source.getFeatures()[this.source.getFeatures().length - 1]
        );
      this.graphicModification.getFeatures().clear();
      this.graphicModification.setActive(true);
      this.modify.setActive(false);
    },
    /**
     * @description: 保存
     * @param {*} data
     * @return {*} addAndEait true修改
     */
    determine(data) {
      this.graphicModification.setActive(true);
      let url = "/enterpriseManage/addLandPlot"; //默认添加
      if (!this.addAndEait) {
        url = "/enterpriseManage/updateLandPlot";
        data.landPlotId = data.id;
        delete data.id;
        delete data.del;
        delete data.createTime;
      }
      if (this.polygonWkt) {
        data.centerPos = this.polygonWkt.centers;
        data.polygonWkt = JSON.stringify(this.polygonWkt.wkt);
      }
      this.$post(url, data).then((res) => {
        if (res.data.state == "success") {
          this.endState = false;
          this.$message.success(res.data.msg);
          this.addMassif();
          this.$router.go(0);
          let datas = res.data.data.landPlotBean;
          if (datas.polygonWkt) {
            if (this.isJSON_test(datas.polygonWkt)) {
              datas.polygonWkt = JSON.parse(datas.polygonWkt);
              let newfeature = new WKT().readFeature(datas.polygonWkt[0]);
              newfeature.massifId = datas.id;
              newfeature.name = datas.name;
              newfeature.explain = "massif";
              this.source.addFeature(newfeature);
            }
          }
          let features = this.source.getFeatures();
          for (var w = 0; w < features.length; w++) {
            if (!features[w].name) {
              this.source.removeFeature(this.source.getFeatures()[w]);
            }
          }
        }
      });
    },
    featuresList(type) {
      if (!type) return;
      const geo = type.getGeometry();
      const t = geo.getType();
      let arrs = [];
      const points = geo.getCoordinates();
      if (!points || points.length == 0) return;
      points[0].map((item) => {
        arrs.push({
          lng: item[0],
          lat: item[1],
        });
      });
      return arrs;
    },
    darwEvent(e) {
      // this.map.getView().fit(e.selected[0].getGeometry().getExtent(), this.map.getSize());
      this.selectedFeature = e.selected;
      this.stceltFace = this.featuresList(e.selected[0]);
      this.ejectEait(e.selected[0]);
      this.newTitle = "编辑基地";
    },
    /**
     * @description: 流程变更吗，不需要编辑，直接选中弹出编辑
     */
    ejectEait(data) {
      this.addAndEait = false;
      this.echoData(data.massifId);
      //   this.eaitMassifInfo();
    },
    /**
     * @description: batchChange
     * @param {*}
     */
    batchChange(r) {
      this.selectByAttribute = r.selected;
      //   this.graphicModification.getFeatures().clear();
      //   this.graphicModification.setActive(false);
      //console.log(r);
      this.graphicModification.getFeatures().clear();
    },
    /**
     * Format length output.
     * @param {LineString} line The line.
     * @return {string} The formatted length.
     */
    formatLength(line) {
      const length = getLength(line);
      let output;
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + " " + "km";
      } else {
        output = Math.round(length * 100) / 100 + " " + "m";
      }
      return output;
    },
    /**
     * Format area output.
     * @param {Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    formatArea(polygon) {
      // //console.log(polygon)
      const area = getArea(polygon);
      let output;
      if (area > 10000) {
        output =
          Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
      } else {
        output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
      }
      return output;
    },
  },
};
</script>
<style lang="less" scoped>
#conter {
  width: 100%;
  height: 100%;
}

#map-container {
  width: 100%;
  height: 90%;
}

.ol-dragbox {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(100, 150, 0.1);
}

// .modelBox {
//   position: absolute;
//   z-index: 1111;
//   top: 55%;
//   background: #fff;
//   left: 50%;
//   width: 58vw;
//   height: 69vh;
//   transform: translate(-50%, -50%);
//   padding: 2vh;
// }
// .modelBox /deep/ .gowthStageItemBox {
//   width: 100%;
// }
.isDialog {
  position: fixed;
  top: 25px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}

.isDialog /deep/ .el-dialog__body {
  padding: 10px 20px;
}
</style>
